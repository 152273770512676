import React from 'react';
import Table from './Table';
import {debounce, take, groupBy, sortBy} from 'lodash';
import ReactModal from 'react-modal';

export default class MineSweeper extends React.Component {
  constructor(props) {
    super(props);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.state = {
      showModal: false,
      name: '',
      winners: this.loadWinners(),
      level: "easy",
      mineNum : 10,
      rowNum : 9,
      colNum : 9,
      flagNum : 0,
      openNum : 0,
      time : 0,
      won: false,
      status : "playing"   // playing, clear, gameover
    };
  }

  loadWinners() {
    return JSON.parse(localStorage.getItem("winners")) || [];
  }

  persistWinners(winners) {
    localStorage.setItem("winners", JSON.stringify(winners));
  }

  componentWillUpdate() {
    if(this.state.status === "playing"){
      this.judge();
    }
  }

  componentWillMount() {
    this.intervals = [];
  }

  tick() {
    if(this.state.openNum > 0 && this.state.status === "playing"){
      const time = (parseFloat(this.state.time) + 0.10).toFixed(1);
      this.setState({time});
    }
  }

  handleCloseModal = () => {
    const {name, time, level} = this.state;
    const winners = [...this.state.winners, {name, time, level}];

    this.setState({winners, showModal: false});
    this.persistWinners(winners);
  }

  judge() {
    if(this.state.mineNum + this.state.openNum >= this.state.rowNum * this.state.colNum){
      this.setState({status: "clear", showModal: true});
    }
  }

  gameOver() {
    this.setState({status: "gameover"});
  }

  checkFlagNum(update) {
    this.setState({flagNum: this.state.flagNum + update});
  }

  setMine(){
    var mineTable = this.state.mineTable;
    for(var i = 0; i < this.state.mineNum; i++){
      var cell = mineTable[Math.floor(Math.random()*10)][Math.floor(Math.random()*10)];
      if(cell.hasMine){
        i--;
      } else {
        cell.hasMine = true;
      }
    }
    this.setState({
      mineTable: mineTable
    });
  }

  addOpenNum() {
    if (this.state.openNum === 0) {
      this.interval = setInterval(this.tick.bind(this), 100);
    }

    this.setState({
      openNum : ++ this.state.openNum
    });
  }

  reset() {
    clearInterval(this.interval);
    this.setState({openNum: 0, flagNum: 0, time: 0, won: false, status: "playing"});
  }

  handleLevelChange(e) {
    clearInterval(this.interval);
    const options = {
      easy:   {mineNum: 10,  rowNum: 9,  colNum: 9},
      normal: {mineNum: 40,  rowNum: 16, colNum: 16},
      hard:   {mineNum: 100, rowNum: 16, colNum: 30}
    };

    const level = Object.keys(options).includes(e.target.value) ? e.target.value : "easy";
    const mineOptions = Object.assign(
      {},
      options[level],
      {
        level,
        openNum: 0,
        flagNum: 0,
        time: 0,
        status: "playing"
      }
    );

    this.setState(mineOptions);
  }

  renderWinners(winners) {
    const sortByFloat = (a,b) => {
      return parseFloat(a.time) - parseFloat(b.time)
    }
    const groups    = groupBy(winners, "level");
    const allGroups = Object.assign({}, {easy: [], normal: [], hard: []}, groups);
    let scores = [];

    ["hard", "normal", "easy"].map(group => {
      take(allGroups[group].sort(sortByFloat), 3).map((winner, index) => {
        scores.push(
          <tr key={`${group}-winner-${index}`}>
            <td>{winner.name}</td>
            <td>{winner.level.charAt(0).toUpperCase() + winner.level.slice(1)}</td>
            <td>{winner.time}</td>
          </tr>
        );
      });
    });

    return scores;
  }

  resetScores = (e) => {
    e.preventDefault();

    if (confirm("Are you sure?")) {
      this.persistWinners([]);
      this.setState({winners: []});
    }
  }

  render() {
    const scoreClass = this.state.winners.length ? "high-scores" : "hide";
    return (
      <div>
        <div className="MineSweeper__level">
          <input type="radio"
                 id="levelEasy"
                 name="level"
                 value="easy"
                 checked={this.state.level === "easy"}
                 onChange={this.handleLevelChange} />
          <label htmlFor="levelEasy">Easy</label>
          <input type="radio"
                 id="levelNormal"
                 name="level"
                 value="normal"
                 onChange={this.handleLevelChange} />
          <label htmlFor="levelNormal">Normal</label>
          <input type="radio"
                 id="levelHard"
                 name="level"
                 value="hard"
                 onChange={this.handleLevelChange} />
          <label htmlFor="levelHard">Hard</label>
        </div>
        <div className={"MineSweeper " + this.state.level}>
          <span className="MineSweeper__flagNum"> {this.state.mineNum - this.state.flagNum}</span>
          <span className="MineSweeper__face" onClick={this.reset.bind(this)}>
            <span className={"button " + this.state.status}></span>
          </span>
          <span className="MineSweeper__time"> {this.state.time}</span>
          <Table status={this.state.status} openNum={this.state.openNum} mineNum={this.state.mineNum} rowNum={this.state.rowNum} colNum={this.state.colNum} gameOver={this.gameOver.bind(this)} addOpenNum={this.addOpenNum.bind(this)} checkFlagNum={this.checkFlagNum.bind(this)}/>

          <div className={scoreClass}>
            <h3>High Scores</h3>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {this.renderWinners(this.state.winners)}
              </tbody>
            </table>
            <a href="#" onClick={this.resetScores}>Reset Scores</a>
          </div>
        </div>
        <ReactModal isOpen={this.state.showModal}
                    contentLabel="a"
                    style={{
                      overlay: {
                        backgroundColor: 'rgba(0,0,0,0.7)'
                      },
                        content: {
                          width: '400px',
                          height: '200px',
                          margin: '0 auto',
                          textAlign: 'center',
                        }
                    }}>
          <h2>Congratulations! 🏆</h2>
          <p>Enter Your Name</p>
          <input className="winner-input" type="text" value={this.state.name} onChange={(e) => {this.setState({name: e.target.value})}} />
          <button disabled={this.state.name.length === 0} onClick={this.handleCloseModal}>Done</button>
        </ReactModal>
      </div>
    );
  }
  }
